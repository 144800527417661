import { Auth } from 'aws-amplify';

const awsmobile = {
  Auth: {
    mandatorySignIn: false,
    region: "eu-west-2",
    userPoolId: "eu-west-2_p0ciwgsDV",
    identityPoolId: "eu-west-2:8cc5c345-74b2-4307-842a-2e2766d2bd6d",
    userPoolWebClientId: "1b1nbtlk5osi40k9822knhpash",
    federationTarget: "COGNITO_USER_POOLS",
    oauth: {
      domain: "auth.animalanalytics.co.uk",
      scope: [
        "email",
        "openid",
        "aws.cognito.signin.user.admin",
        "profile",
        "phone"
      ],
      redirectSignIn: 'https://animalanalytics.co.uk/',
      //redirectSignIn: "http://localhost:3000/",
      redirectSignOut: 'https://animalanalytics.co.uk/logout/',
      //redirectSignOut: "http://localhost:3000/logout/",
      responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  API: {
    endpoints: [
      {
        name: "Training",
        endpoint: 'https://api.animalanalytics.co.uk/training',
        custom_header: async () => { 
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }
};


export default awsmobile;
