import React, { useState, useEffect } from 'react';
import { Hub, Cache } from 'aws-amplify';
import {
    Card, Button, OverlayTrigger,
    Popover, Badge
} from 'react-bootstrap';

import uuid from 'uuid/v4';
import { Logger } from 'aws-amplify';

const logger = new Logger('NotificationCentre');

export const NotificationTypes = {
    INFO: 'information',
    ERROR: 'error',
    WARNING: 'warning'
}

export function notify(
    _title,
    _message,
    _type = NotificationTypes.INFO,
    _timeout = 3
) {
    const _id = uuid().toString();

    Hub.dispatch('notification', {
        id: _id,
        title: _title,
        message: _message,
        date: new Date(),
        type: _type,
        timeout: _timeout,
        seen: false
    });

    return _id;
}

export default class NotificationCentre extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            refs: [],
            unread: 0
        }

        this.clearAll = this.clearAll.bind(this);
        this.onNotification = this.onNotification.bind(this);
        this.mapCached = this.mapCached.bind(this);

        this.buttonRef = React.createRef();
        this.containerRef = React.createRef();

        const callback = entries => {
            logger.debug('Enteries: ', entries);
        };

        this.observer = new IntersectionObserver(callback, {
            root: this.containerRef.current,
            threshold: new Array(101).fill(0).map((v, i) => i * 0.01),
        });
    }

    getCached = () => {
        const previous = Cache.getItem('notifications');
        if (previous != null && typeof previous[Symbol.iterator] === 'function') {
            return previous;
        } else {
            return [];
        }
    }

    mapCached = () => {
        if (this.buttonRef.current != null) {
            const cached = this.getCached();
            var _notifications = [];
            var _refs = []

            for (const notification in cached) {
                const loopRef = React.createRef();
                const loopNotification = (
                    <NotificationWrapper
                        key={cached[notification]['id']}
                        title={cached[notification]['title']}
                        message={cached[notification]['message']}
                        date={new Date(cached[notification]['date'])}
                        type={cached[notification]['type']}
                        timeout={cached[notification]['timeout']}
                        seen={cached[notification]['seen']}
                        ref={loopRef}
                    />
                );

                _notifications = [
                    ..._notifications,
                    loopNotification
                ]

                this.setState({ notifications: _notifications });
                this.setState({ unread: _notifications.length });

                _refs = [
                    ..._refs,
                    loopRef
                ]

                this.setState({ refs: _refs });

            }
        }
    }

    componentDidMount = () => {
        Hub.listen("notification", this.onNotification);
        this.mapCached();

        Object.values(this.state.refs).forEach(value =>
            this.observer.observe(value.current),
        );

    }

    componentDidUpdate = () => {
        //this.observer.observe(loopRef);
    }

    componentWillUnmount = () => {
        Hub.remove("notification");
    }


    onNotification = (data) => {
        const previous = this.getCached();

        Cache.setItem('notifications', [
            data.payload,
            ...previous
        ]);

        this.mapCached();
    };

    clearAll = () => {
        Cache.removeItem('notifications');
        this.setState({
            notifications: [],
            unread: 0
        });
    }

    render() {

        return (
            <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom"
                overlay={
                    <Popover style={{
                        width: '20rem',
                        position: 'fixed!important'
                    }}>
                        <Popover.Title as="h3" style={{ borderBottom: 'none' }}>Notifications{this.state.notifications.length > 0 && <Button size="xs" onClick={this.clearAll} variant="light-green">Clear</Button>}</Popover.Title>
                        <Popover.Content ref={this.containerRef}>
                            {this.state.notifications.length > 0 ? this.state.notifications : <NotificationItem message="No notifications" />}
                        </Popover.Content>
                    </Popover>
                }>
                <Button size="sm" variant={this.props.variant} ref={this.buttonRef}>
                    {this.state.unread > 0 &&
                        <>
                            <Badge style={{
                                margin: '0',
                                top: '-.5rem',
                                left: '-.5rem',
                                position: 'absolute'
                            }} variant="danger">{this.state.unread}</Badge>
                            <span className="sr-only">unread messages</span>
                        </>
                    }
                    Notifications
            </Button>
            </OverlayTrigger>
        )
    }
}

const Notification = (props, ref) => {

    const initial_diff = Math.round(Math.abs(new Date() - props.date) / 1000);
    const [timer, setTimer] = useState(initial_diff < 43200 ? initial_diff : props.date);
    //const ref = React.createRef();

    useEffect(() => {
        if (initial_diff < 43200) {
            var timerID = setInterval(() => tick(), 20000);

            return () => {
                clearInterval(timerID);
            };
        }
    });

    const tick = () => {
        const diff = Math.round(Math.abs(new Date() - props.date) / 1000);
        setTimer(diff);
    }

    const formatSeconds = (secs) => {
        if (secs < 43200) {
            var mins = Math.round(secs / 60);
            var hrs = Math.round(mins / 60);
            if (hrs === 0 && mins === 0) {
                return 'now'
            } else {
                if (hrs === 0) {
                    return (mins > 1 ? mins + ' mins' : mins + ' min') + ' ago';
                } else {
                    return (hrs > 1 ? hrs + ' hrs' : hrs + ' hr') + ' ago';
                }
            }
        } else {
            const d = new Date(secs);
            const time_array = d.toTimeString().split(':').slice(0, 2);
            const date = d.toLocaleDateString();
            return time_array[0] + ':' + time_array[1] + ' ' + date;
        }
    }
    return (
        <NotificationItem
            title={props.title}
            message={props.message}
            age={formatSeconds(timer)}
            type={props.type}
            forwardedRef={ref} />
    );
};


const NotificationItem = (props) => (
    <>
        <hr style={{ margin: '0' }} />
        <Card ref={props.forwardedRef} style={{ borderWidth: 0, width: '100%' }} variant={props.type}>
            <Card.Body style={{ padding: '1rem' }}>
                {props.title && <Card.Title>{props.title}</Card.Title>}
                {props.age && <Card.Subtitle className="mb-2 text-muted">{props.age}</Card.Subtitle>}
                {props.message && <Card.Text>{props.message}</Card.Text>}
                {props.link && <Card.Link href="{props.href}">{props.link}</Card.Link>}
            </Card.Body>
        </Card>
    </>
);

const NotificationWrapper = React.forwardRef(Notification);

/*
async function initialiseObserver() {
    if (!('IntersectionObserver' in window)) {
        await import('intersection-observer');
    }
    const observer = IntersectionObserver(callback, options);
}
*/