import React from "react";
import Avatar, { AvatarItem } from "@atlaskit/avatar";
import { EXAMPLE_VET_USERS } from "../DummyData";

// import { RANDOM_USERS, getAdorableAvatar } from "../examples-util/data";

const getVetProfiles = () => {
    return EXAMPLE_VET_USERS;
};

const onClickHandeler = (props) => {
    console.log('Vet Selected: ', props);
};

export default () => {
  const data = getVetProfiles();

  const vets = data[0];
  const nurses = data[1];
  console.log('Vets', vets);

  const vetAvatartList = vets.options.map(user => ({
    label: (
      <AvatarItem
        avatar={<Avatar src={user.src} presence={user.presence} />}
        key={user.email}
        onClick={onClickHandeler}
        primaryText={user.name}
        secondaryText={user.email}
      />
    ),
    value: user.name
  }));


  const nurseAvatartList = nurses.options.map(user => ({
    label: (
      <AvatarItem
        avatar={<Avatar src={user.src} presence={user.presence} />}
        key={user.email}
        onClick={onClickHandeler}
        primaryText={user.name}
        secondaryText={user.email}
      />
    ),
    value: user.name
  }));

  const allAvatarts = [
    {
      label: vets.label,
      options: vetAvatartList
    },
    {
      label: nurses.label,
      options:nurseAvatartList
    }
  ]
  return allAvatarts;
};
