import React, { useContext, useState } from 'react';
import { Link } from '@reach/router';
import {
    Container, Row, Col, Spinner, Navbar,
    Button, ButtonGroup, Modal, Dropdown,
    DropdownButton, Nav, Image
} from 'react-bootstrap';
import { Auth, Logger } from 'aws-amplify';
import UserContext from '../Context';
import NotificationCentre from './NotificationCentre';
import { Logo } from './Branding';

const logger = new Logger('UIComponents');

const NavLink = props => (
    <Nav.Link as={Link}
        {...props}
        getProps={({ isCurrent }) => {
            if (isCurrent) {
                return {
                    style: {
                        color: "rgba(0, 0, 0, 1)"
                    }
                };
            } else {
                return {}
            }

        }} />
);

export const Navigation = (props) => {
    const userContext = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const logout = (_global) => {
        closeModal();
        logger.debug('logout');
        userContext.setLoggingout();
        Auth.signOut({ global: _global })
            .then(() => {
                userContext.setCredentials(null);
            })
            .catch((err) => {
                logger.error('Couldn\'t sign out');
                logger.debug(err);
                userContext.setCredentials(null);
            })
    }

    return (
        <>
            <Navbar variant="light" bg="brand-light" fixed="top" expand="lg" >
                <Navbar.Brand as={Link} to="/"><Logo variant="brand-dark" /></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav className="mr-auto">
                        <Nav.Item>
                            <NavLink as={Link} to="/">Home</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink as={Link} to="/dashboard">Dashboard</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink as={Link} to="/training">Training</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink as={Link} to="/appointments">Appointments</NavLink>
                        </Nav.Item>
                    </Nav>
                    <ButtonGroup aria-label="Basic example">
                        {userContext.credentials ? (
                            <>
                                <NotificationCentre variant="dark" />
                                <DropdownButton as={ButtonGroup} size="sm" variant="dark" alignRight drop="down"
                                    title={
                                                    <Image src={userContext.credentials.picture} style={{ width: '35px', height: '35px' }} roundedCircle />
                                    }>
                                    <Dropdown.Item as={Link} to="/profile">My Profile</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/security">Security</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item as={Button} onClick={openModal}>Sign out</Dropdown.Item>
                                </DropdownButton>
                            </>
                        ) : <Button variant="brand-dark" as={Link} to="/login" >Sign in</Button>}
                    </ButtonGroup>
                </Navbar.Collapse>

            </Navbar>
            <LogoutModal
                show={showModal}
                onHide={closeModal}
                onLogout={logout}
                onFullLogout={() => { logout(true) }} />
        </>
    )
}

export const Loading = (props) => {
    return (
        <Container className="middle-align">
            <Row>
                <Col sm style={{ textAlign: 'center' }}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    {props.children}
                </Col>
            </Row>
        </Container>
    )
}

function LogoutModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you leaving?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
                <Dropdown as={ButtonGroup}>
                    <Button onClick={props.onLogout} variant="brand-dark">Logout</Button>

                    <Dropdown.Toggle split variant="brand-dark" id="dropdown-split-basic" />

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={props.onLogout}>Logout of this device</Dropdown.Item>
                        <Dropdown.Item onClick={props.onFullLogout}>Logout of all devices</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Modal.Body>
        </Modal>
    );
}

export const HomeButton = (props) => {
    return <Button as={Link} to="/" size="sm" variant="brand-dark">Home</Button>
}

export const CenteringContainer = (props) => {
    return (
        <Container className="middle-align centre-align">
            {props.children}
        </Container>
    )
}

export const Centering = (props) => {
    return (
        <CenteringContainer>
            <Row>
                <Col>
                    {props.children}
                </Col>
            </Row>
        </CenteringContainer>
    )
}

export const GradientText = (props) => {
    return (
        <span {...props} className={props.variant + '-gradient text-clip'}>
            {props.children}
        </span>
    )
}



/*
export class Login extends React.Component {

    static contextType = UserContext;

    render() {
        return (<p>Please sign in</p>)
    }
}

export async function checkUser() {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (e) {
        return false;
    }
}

export const Logout = (props) => {
    return (
        <Container className="middle-align">
            <Row>
                <Col sm style={{ textAlign: 'center' }}>
                    <h1>Thanks for uploading!</h1>
                    <p>You've been logged out, come again soon.</p>
                </Col>
            </Row>
        </Container>
    );
}


export const UnprotectedComponent = (props) => {
    let { as: Comp, ...other_props } = props;
    return (
        <Container fluid className="main">
            <Comp {...other_props} />
        </Container>
    )
}

export class ProtectedComponent extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };

        this.handleResult = this.handleResult.bind(this);
    }

    handleResult(data) {
        if (data) {
            this.context.setCredentials(data);
        } else {
            this.context.setCredentials(null);
        }
        this.setState({ loading: false });
    }

    componentDidMount() {
        this.setState({ loading: true });
        checkUser().then(this.handleResult);
    }

    render() {
        var content = <Login />
        if (this.state.loading) {
            content = <Loading />

        } else {
            if (this.context.user) {
                let { as: Comp, ...other_props } = this.props;
                content = <Comp {...other_props} />
            } else {
                content = <Login />
            }
        }

        return (
            <Container fluid className="main">
                {content}
            </Container>
        );
    }
}


/*
export const ProtectedComponent = (props) => {

    const userContext = useContext(UserContext);

    const [loading, setLoading] = useState(true);

    var content = <Login />

    const handleResult = (user) => {
        if (user) {
            userContext.setCredentials(user);
        } else {
            userContext.setCredentials(null);
        }
        setLoading(false);
    }


    let { as: Comp, ...other_props } = props;
    if (userContext.user) {
        //setLoading(false);
        content = <Comp {...other_props} />
    } else {
        checkUser().then(handleResult);
    }

    if (loading) {
        content = <Loading />
    }



    return (
        <Container fluid className="main">
            {content}
        </Container>
    );
}



*/