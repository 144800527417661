import React, { useState, useContext } from "react";
import { Auth, Cache, Logger } from "aws-amplify";
import { Link, navigate } from "@reach/router";
import { Loading, CenteringContainer } from "./components/UIComponents";
import { FaFacebook, FaGoogle, FaAmazon, FaEnvelope } from "react-icons/fa";
import { Col, Accordion, Form, Button, Spinner } from "react-bootstrap";

import UserContext from "./Context";
//import { notify } from './components/NotificationCentre';
const logger = new Logger("AuthHelpers");

logger.debug("AuthHelpers");

export class Protect extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      loadingMessage: null
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.timer = null;
  }

  handleLogin(user) {
    if (user) {
      this.context.setCredentials(user);
    } else {
      this.context.setCredentials(null);
    }
    this.context.setNominal();
  }

  componentDidMount() {
    this.context.setChecking();
    if (this.context.phase) {
      // Don't check for a signed in user if there
      // is something happening, like logging in.
      this.timer = setTimeout(() => {
        this.setState({
          loadingMesssage: <p>This is taking longer than it should...</p>
        });
      }, 3000);
    } else {
      // Check whether the user is logged in
      checkUser().then(this.handleLogin);
    }
  }

  componentWillUnmount() {
    // If a timeout was set, cancel it
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    if (this.context.phase) {
      return <Loading>{this.state.loadingMesssage}</Loading>;
    } else {
      return <Login redirect={this.props.redirect} />;
    }
  }
}

export async function checkUser() {
  logger.debug("Check User");
  try {
    let user = await Auth.currentAuthenticatedUser();
    logger.info("Current User: ", user);
    const { attributes } = user;
    return attributes;
  } catch (e) {
    return null;
  }
}

export async function signUp(
  { firstName, lastName, password, confirmPassword, email },
  updateFormType
) {
    const username = email;
  const name = firstName.concat(" ", lastName);
  if (password === confirmPassword) {
    logger.debug("Password Match");
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email: email,
          name: name
        }
      });
      logger.debug("sign up success!");
      updateFormType("confirmSignUp");
    } catch (err) {
      logger.error("error signing up..", err);
    }
  }
}
// export async function signUp({ username, password, confirmPassword, email }, updateFormType) {
//     logger.error("Info", username, password, confirmPassword, email);
// //   try {
// //     await Auth.signUp({
// //       username,
// //       password,
// //       attributes: { email }
// //     });
// //     logger.debut("sign up success!");
// //     updateFormType("confirmSignUp");
// //   } catch (err) {
// //     logger.error("error signing up..", err);
// //   }
// }

export async function confirmSignUp(
  { email, confirmationCode },
  updateFormType
) {
    const username = email;
  try {
    await Auth.confirmSignUp(username, confirmationCode);
    logger.debug("confirm sign up success!");
    updateFormType("signIn");
  } catch (err) {
    logger.error("error signing up..", err);
  }
}
export const Login = props => {
  const userContext = useContext(UserContext);
  if (userContext.credentials) {
    navigate("/");
    return <p>You're already logged in</p>;
  } else {
    // You could replace this with a form to log-in with
    return (
      <CenteringContainer>
        <Col
          xs={12}
          sm={10}
          md={8}
          lg={5}
          className="with-shadow"
          style={{ borderRadius: "1rem", padding: "2rem" }}
        >
          <h1>Sign in</h1>
          <p>You can sign in whichever way you prefer</p>
          <LoginOptions redirect={props.redirect} />
        </Col>
      </CenteringContainer>
    );
  }
};

export const Logout = props => {
  const handleLogout = credentials => {
    logger.debug("Credentials", credentials);
    if (credentials) {
      //notify()
    }
  };

  checkUser().then(handleLogout);

  const userContext = useContext(UserContext);
  if (userContext.credentials) {
    navigate("/");
    return <p>You're not logged in</p>;
  } else {
    return (
      <CenteringContainer>
        <Col
          xs={12}
          sm={10}
          md={8}
          lg={5}
          className="with-shadow"
          style={{ borderRadius: "1rem", padding: "2rem" }}
        >
          <h1>Sign in!</h1>
          <p>You can sign in whichever way you prefer</p>
          <LoginOptions />
        </Col>
      </CenteringContainer>
    );
  }
};

const LoginOptions = props => {
  const userContext = useContext(UserContext);

  const federatedSignIn = _provider => {
    if (props.redirect) {
      Cache.setItem("auth_redirect", props.redirect);
    }
    userContext.setLoggingin();
    Auth.federatedSignIn({ provider: _provider });
  };

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Collapse eventKey="0">
        <>
          <Button
            block
            onClick={() => {
              federatedSignIn("Google");
            }}
            variant="google"
          >
            <FaGoogle
              color="white"
              className="mr-3"
              style={{ marginBottom: ".125rem" }}
            >
              Sign in using Google
            </FaGoogle>
            Sign in with Google
          </Button>
          <Button
            block
            onClick={() => {
              federatedSignIn("Facebook");
            }}
            variant="facebook"
          >
            <FaFacebook
              color="white"
              className="mr-3"
              style={{ marginBottom: ".125rem" }}
            >
              Sign in using Facebook
            </FaFacebook>
            Sign in with Facebook
          </Button>
          <Button
            block
            onClick={() => {
              federatedSignIn("LoginWithAmazon");
            }}
            variant="amazon"
          >
            <FaAmazon
              color="white"
              className="mr-3"
              style={{ marginBottom: ".125rem" }}
            >
              Sign in using Amazon
            </FaAmazon>
            Sign in with Amazon
          </Button>
          <hr />
          <Accordion.Toggle as={Button} block variant="secondary" eventKey="1">
            <FaEnvelope
              color="white"
              className="mr-3"
              style={{ marginBottom: ".125rem" }}
            >
              Sign in using email
            </FaEnvelope>
            Sign in with email
          </Accordion.Toggle>
        </>
      </Accordion.Collapse>
      <Accordion.Collapse eventKey="1">
        <>
          <Accordion.Toggle as={Button} block variant="secondary" eventKey="0">
            Show all sign in options
          </Accordion.Toggle>
          <hr />
          <LoginForm />
          <p style={{ margin: "1rem 0 0 0" }}>
            <span>Don't have an account yet?</span>
            <Button className="ml-2" as={Link} to="/signup">
              Sign up
            </Button>
          </p>
        </>
      </Accordion.Collapse>
    </Accordion>
  );
};

function LoginForm(props) {
  const ControlForm = props => {
    const userContext = useContext(UserContext);

    const callback = inputs => {
      userContext.setLoggingin();
      Auth.signIn(inputs.email, inputs.password);
    };

    const { inputs, disabled, handleInputChange, handleSubmit } = useSignInForm(
      callback
    );

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group controlId="aaEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Enter email"
            autoComplete="on"
            onChange={handleInputChange}
            value={inputs.email}
            disabled={disabled}
          />
          {/*
                    <Form.Text className="text-muted">
                        This is the email address that you registered with Animal Analytics.
                    </Form.Text>
                    */}
        </Form.Group>

        <Form.Group controlId="aaPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Password"
            autoComplete="off"
            onChange={handleInputChange}
            value={inputs.password}
            disabled={disabled}
          />
        </Form.Group>
        {/*
                <Form.Group controlId="keepSignedIn">
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Keep me signed in"
                    />
                </Form.Group>
                */}
        <Button block variant="primary" type="submit" disabled={disabled}>
          {disabled ? (
            <Spinner animation="grow" size="sm" role="status">
              <span className="sr-only">Signing in...</span>
            </Spinner>
          ) : (
            "Sign in"
          )}
        </Button>
      </Form>
    );
  };
  return <ControlForm />;
}

const useSignInForm = callback => {
  const [disabled, setDisabled] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: ""
  });
  const handleSubmit = event => {
    setDisabled(true);
    if (event) {
      event.preventDefault();
    }
    callback(inputs);
    return null;
  };
  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };
  return {
    handleSubmit,
    handleInputChange,
    disabled,
    inputs
  };
};
