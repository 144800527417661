import React from "react";
import events from "../DummyData";
import { Calendar, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

// import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import {
  Row,
  Col,
} from "react-bootstrap";
import VetSelect from './VetSelect';

const DragAndDropCalendar = withDragAndDrop(Calendar);

class AppointmentBooker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: events
    };

    this.moveEvent = this.moveEvent.bind(this);
    this.newEvent = this.newEvent.bind(this);
  }

  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
    const { events } = this.state;

    const idx = events.indexOf(event);
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    const updatedEvent = { ...event, start, end, allDay };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    this.setState({
      events: nextEvents
    });

    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }

  resizeEvent = ({ event, start, end }) => {
    const { events } = this.state;

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });

    this.setState({
      events: nextEvents
    });

    //alert(`${event.title} was resized to ${start}-${end}`)
  };
  handleSelect = ({ start, end }) => {
    const title = window.prompt("New Event name");
    if (title)
      this.setState({
        events: [
          ...this.state.events,
          {
            start,
            end,
            title
          }
        ]
      });
  };
  newEvent(event) {
    console.log('New Event');
  }
  eventPropGetter = event => {
    let newStyle = {
      backgroundColor: "lightgrey",
      color: "black",
      borderRadius: "0px",
      border: "none"
    };
    if (event.isMine) {
      newStyle.backgroundColor = "lightgreen";
    }
    return {
      style: newStyle
    };
  };
  
  render() {
    return (
      <Row>
        <Col xs={2} md={2}>
          <VetSelect />
        </Col>
        <Col className="appointments">
          <DragAndDropCalendar
            popup
            selectable
            localizer={this.props.localizer}
            events={this.state.events}
            onEventDrop={this.moveEvent}
            resizable
            onEventResize={this.resizeEvent}
            // onSelectSlot={this.newEvent}
            onDragStart={console.log}
            defaultView={Views.WEEK}
            defaultDate={new Date(2015, 3, 12)}
            onSelectEvent={event => alert(event.title)}
            onSelectSlot={this.handleSelect}
            // eventPropGetter={this.eventPropGetter}
          />
        </Col>
      </Row>
    );
  }
}

export default AppointmentBooker;
