import React, { useState, useReducer } from "react";
import { signUp, confirmSignUp } from "../AuthHelpers";
import { Link } from "@reach/router";
import { Container, Button, InputGroup, FormControl } from "react-bootstrap";

const initialFormState = {
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
  email: "",
  confirmationCode: ""
};

function reducer(state, action) {
  switch (action.type) {
    case "updateFormState":
      return {
        ...state,
        [action.e.target.name]: action.e.target.value
      };
    default:
      return state;
  }
}

export default function Form() {
  const [formType, updateFormType] = useState("signUp");
  const [formState, updateFormState] = useReducer(reducer, initialFormState);
  function renderForm() {
    switch (formType) {
      case "signUp":
        return (
          <SignUp
            signUp={() => signUp(formState, updateFormType)}
            updateFormState={e =>
              updateFormState({ type: "updateFormState", e })
            }
          />
        );
      case "confirmSignUp":
        return (
          <ConfirmSignUp
            confirmSignUp={() => confirmSignUp(formState, updateFormType)}
            updateFormState={e =>
              updateFormState({ type: "updateFormState", e })
            }
          />
        );
      default:
        return null;
    }
  }

  return (
    <Container>
      <Container>{renderForm(formState)}</Container>
      {formType === "signUp" && (
        <p style={styles.footer}>
          Already have an account?
          <Button variant="link" style={styles.anchor} as={Link} to="/login">
            Sign In
          </Button>
        </p>
      )}
    </Container>
  );
}

function SignUp(props) {
  return (
    <Container style={styles.container}>
      <InputGroup style={styles.inputGroup}>
        <FormControl
          name="firstName"
          onChange={e => {
            e.persist();
            props.updateFormState(e);
          }}
          style={styles.input}
          placeholder="First Name"
        />
        <FormControl
          name="lastName"
          onChange={e => {
            e.persist();
            props.updateFormState(e);
          }}
          style={styles.input}
          placeholder="Last Name"
        />
      </InputGroup>
      <InputGroup style={styles.inputGroup}>
        <FormControl
          name="email"
          onChange={e => {
            e.persist();
            props.updateFormState(e);
          }}
          style={styles.input}
          placeholder="Email"
        />
      </InputGroup>
      <InputGroup style={styles.inputGroup}>
        <FormControl
          type="password"
          name="password"
          onChange={e => {
            e.persist();
            props.updateFormState(e);
          }}
          style={styles.input}
          placeholder="Password"
        />
      </InputGroup>
      <InputGroup style={styles.inputGroup}>
        <FormControl
          type="password"
          name="confirmPassword"
          onChange={e => {
            e.persist();
            props.updateFormState(e);
          }}
          style={styles.input}
          placeholder="Confirm Password"
        />
      </InputGroup>
      <Button
        onClick={props.signUp}
        style={styles.button}
      >
        Sign Up
      </Button>
    </Container>
  );
}

function ConfirmSignUp(props) {
  return (
      <Container>
      <InputGroup style={styles.inputGroup}>
        <FormControl
          name="confirmationCode"
          placeholder="Confirmation Code"
          onChange={e => {
            e.persist();
            props.updateFormState(e);
          }}
          style={styles.input}
        />
      </InputGroup>
      <Button onClick={props.confirmSignUp} style={styles.button}>
        Confirm Sign Up
      </Button>
    </Container>
  );
}
  
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: 150,
    justifyContent: "center",
    alignItems: "center"
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  input: {
    height: 45,
    marginTop: 8,
    width: 300,
    maxWidth: 300,
    padding: "0px 8px",
    fontSize: 16,
    outline: "none",
    border: "none",
    borderBottom: "2px solid rgba(0, 0, 0, .3)"
  },
  button: {
    backgroundColor: "#006bfc",
    color: "white",
    width: 316,
    height: 45,
    marginTop: 10,
    fontWeight: "600",
    fontSize: 14,
    cursor: "pointer",
    border: "none",
    outline: "none",
    borderRadius: 3,
    boxShadow: "0px 1px 3px rgba(0, 0, 0, .3)"
  },
  footer: {
    fontWeight: "600",
    padding: "0px 25px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.6)"
  },
  anchor: {
    color: "#006bfc",
    cursor: "pointer"
  }
};
