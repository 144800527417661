import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Logger } from 'aws-amplify';

const logger = new Logger('Dashboard');


const Dashboard = (props) => {
    logger.info('Dashboard');
    return (
        <Container>
            <Row>
                <Col>
                    <p>Dashboard</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Dashboard;