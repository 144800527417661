import React from "react";

import "font-awesome/css/font-awesome.min.css";

import "react-big-calendar/lib/sass/styles.scss";
import AppointmentBooker from "../components/AppointmentBooker";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";

const globalizeLocalizer = momentLocalizer(moment);

export default class Calendar extends React.Component {

  render() {

    return (
        <AppointmentBooker localizer={globalizeLocalizer} />
    );
  }
}
