import Sky from "./images/Sky.jpg";
const now = new Date();
// TODO update to support colour of avtar component 
export const EXAMPLE_VET_USERS = 
[
  {
  label: 'Vets',
  options: [
         {
           id: 1,
           name: "Dr A",
           email: "joshua.wa.cole@gmail.com",
           src: Sky,
           presence: "focus",
           color: "#666666"
         },
         {
           id: 1,
           name: "Dr A",
           email: "joshua.wa.cole@gmail.com",
           src: Sky,
           presence: "busy",
           color: "#666666"
         },
         {
           id: 1,
           name: "Dr A",
           email: "joshua.wa.cole@gmail.com",
           src: Sky,
           presence: "online",
           color: "#666666"
         }]
        }
        ,
         {
           label:'Nurses',
           options: [
             {
           id: 1,
           name: "Dr A",
           email: "joshua.wa.cole@gmail.com",
           src: Sky,
           presence: "offline",
           color: "#666666"
         },
         {
           id: 1,
           name: "Dr A",
           email: "joshua.wa.cole@gmail.com",
           src: Sky,
           presence: "focus",
           color: "#666666"
         },
         {
           id: 1,
           name: "Dr A",
           email: "joshua.wa.cole@gmail.com",
           src: Sky,
           presence: "busy",
           color: "#666666"
         },
         {
           id: 1,
           name: "Dr A",
           email: "joshua.wa.cole@gmail.com",
           src: Sky,
           presence: "online",
           color: "#666666"
         },
         {
           id: 1,
           name: "Dr A",
           email: "joshua.wa.cole@gmail.com",
           src: Sky,
           presence: "offline",
           color: "#666666"
         }
       ]
      }
];

const appointments = [
  {
    id: 0,
    userName: "1",

    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2015, 3, 0),
    end: new Date(2015, 3, 1)
  },
  {
    id: 1,
    userName: "2",
    title: "Long Event",
    start: new Date(2015, 3, 7),
    end: new Date(2015, 3, 10)
  },

  {
    id: 2,
    userName: "3",
    title: "DTS STARTS",
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0)
  },

  {
    id: 3,
    userName: "4",
    title: "DTS ENDS",
    start: new Date(2016, 10, 6, 0, 0, 0),
    end: new Date(2016, 10, 13, 0, 0, 0)
  },

  {
    id: 4,
    userName: "Josh",
    title: "Some Event",
    start: new Date(2015, 3, 9, 0, 0, 0),
    end: new Date(2015, 3, 10, 0, 0, 0),
    isMine: true
  },
  {
    id: 5,
    userName: "Bill",
    title: "Conference",
    start: new Date(2015, 3, 11),
    end: new Date(2015, 3, 13),
    desc: "Big conference for important people"
  },
  {
    id: 6,
    userName: "5",
    title: "Meeting",
    start: new Date(2015, 3, 12, 10, 30, 0, 0),
    end: new Date(2015, 3, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting"
  },
  {
    id: 7,
    userName: "6",
    title: "Lunch",
    start: new Date(2015, 3, 12, 12, 0, 0, 0),
    end: new Date(2015, 3, 12, 13, 0, 0, 0),
    desc: "Power lunch"
  },
  {
    id: 8,
    userName: "7",
    title: "Meeting",
    start: new Date(2015, 3, 12, 14, 0, 0, 0),
    end: new Date(2015, 3, 12, 15, 0, 0, 0)
  },
  {
    id: 9,
    userName: "8",
    title: "Happy Hour",
    start: new Date(2015, 3, 12, 17, 0, 0, 0),
    end: new Date(2015, 3, 12, 17, 30, 0, 0),
    desc: "Most important meal of the day"
  },
  {
    id: 10,
    userName: "9",
    title: "Dinner",
    start: new Date(2015, 3, 12, 20, 0, 0, 0),
    end: new Date(2015, 3, 12, 21, 0, 0, 0)
  },
  {
    id: 11,
    userName: "10",
    title: "Birthday Party",
    start: new Date(2015, 3, 13, 7, 0, 0),
    end: new Date(2015, 3, 13, 10, 30, 0)
  },
  {
    id: 12,
    userName: "11",
    title: "Late Night Event",
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 18, 2, 0, 0)
  },
  {
    id: 12.5,
    userName: "12",
    title: "Late Same Night Event",
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 17, 23, 30, 0)
  },
  {
    id: 13,
    userName: "13",
    title: "Multi-day Event",
    start: new Date(2015, 3, 20, 19, 30, 0),
    end: new Date(2015, 3, 22, 2, 0, 0)
  },
  {
    id: 14,
    userName: "14",
    title: "Today",
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3))
  },
  {
    id: 15,
    userName: "15",
    title: "Point in Time Event",
    start: now,
    end: now
  },
  {
    id: 16,
    userName: "16",
    title: "Video Record",
    start: new Date(2015, 3, 14, 15, 30, 0),
    end: new Date(2015, 3, 14, 19, 0, 0)
  },
  {
    id: 17,
    userName: "17",
    title: "Dutch Song Producing",
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0)
  },
  {
    id: 18,
    userName: "18",
    title: "Itaewon Halloween Meeting",
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 17, 30, 0)
  },
  {
    id: 19,
    userName: "19",
    title: "Online Coding Test",
    start: new Date(2015, 3, 14, 17, 30, 0),
    end: new Date(2015, 3, 14, 20, 30, 0)
  },
  {
    id: 20,
    userName: "20",
    title: "An overlapped Event",
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0)
  },
  {
    id: 21,
    userName: "21",
    title: "Phone Interview",
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0)
  },
  {
    id: 22,
    userName: "22",
    title: "Cooking Class",
    start: new Date(2015, 3, 14, 17, 30, 0),
    end: new Date(2015, 3, 14, 19, 0, 0)
  },
  {
    id: 23,
    userName: "23",
    title: "Go to the gym",
    start: new Date(2015, 3, 14, 18, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0)
  },
  {
    id: 24,
    userName: "24",
    title: "Go to the gym",
    start: new Date(2015, 3, 14, 18, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0)
  },
  {
    id: 25,
    userName: "25",
    title: "Go to the gym",
    start: new Date(2015, 3, 14, 18, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0)
  }
];

export default appointments