import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GradientText } from '../components/UIComponents';
import { Logger } from 'aws-amplify';

const logger = new Logger('Home');

const Home = (props) => {
    logger.info('Home');
    return (
        <Container>
            <Row>
                <Col>
                    <h1><GradientText variant="purple">Home</GradientText></h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eget dolor morbi non arcu. Leo a diam sollicitudin tempor id eu nisl nunc mi. Velit laoreet id donec ultrices. Viverra vitae congue eu consequat ac felis donec et. Urna neque viverra justo nec ultrices dui sapien. Mauris ultrices eros in cursus turpis massa tincidunt. Suscipit tellus mauris a diam maecenas sed enim ut. Tempor nec feugiat nisl pretium fusce. Fames ac turpis egestas maecenas pharetra convallis posuere morbi. Nullam ac tortor vitae purus faucibus ornare.</p>
                    <p>Interdum varius sit amet mattis. Nibh praesent tristique magna sit amet purus gravida. Ornare massa eget egestas purus viverra. Duis tristique sollicitudin nibh sit amet commodo. Eu sem integer vitae justo. Justo eget magna fermentum iaculis eu non diam phasellus vestibulum. Et odio pellentesque diam volutpat commodo. Ultricies mi quis hendrerit dolor magna. Feugiat vivamus at augue eget arcu dictum varius duis at. Pretium quam vulputate dignissim suspendisse in est. At urna condimentum mattis pellentesque id. Ullamcorper a lacus vestibulum sed arcu non odio euismod lacinia. Dignissim suspendisse in est ante in nibh mauris cursus. Id interdum velit laoreet id donec. Vitae turpis massa sed elementum. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur.</p>
                    <p>Mauris commodo quis imperdiet massa. Lorem ipsum dolor sit amet consectetur adipiscing elit duis. Nam libero justo laoreet sit. Scelerisque fermentum dui faucibus in ornare quam. Et tortor consequat id porta nibh venenatis cras sed felis. Nec ultrices dui sapien eget mi. Aenean euismod elementum nisi quis eleifend quam adipiscing vitae. Adipiscing enim eu turpis egestas pretium aenean pharetra magna. Urna condimentum mattis pellentesque id nibh. Ornare suspendisse sed nisi lacus sed viverra tellus in hac. Donec pretium vulputate sapien nec sagittis aliquam. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Odio ut sem nulla pharetra diam sit amet nisl suscipit. Auctor eu augue ut lectus arcu bibendum at. Praesent semper feugiat nibh sed. Dignissim suspendisse in est ante.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Home;