import React, { useState, useEffect } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { API, Logger } from 'aws-amplify';
import { Centering } from '../components/UIComponents';

const logger = new Logger('Training');


const TrainingButton = (props) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonContent, setButtonContent] = useState(props.children);
    const [buttonVariant, setButtonVariant] = useState('primary');
    const [startTimestamp, setStartTimestamp] = useState(null);

    function startPress() {

        setStartTimestamp(Date.now());
        setButtonContent('Recording...');
    }

    function endPress() {

        if (props.device == null) {
            alert('No device selected!');
            resetButton();
            return
        }

        setButtonDisabled(true);
        setButtonVariant('warning');


        setButtonContent('Saving...');

        function resetButton() {
            setButtonVariant('primary');
            setButtonContent(props.children);
            setStartTimestamp(null);
            setButtonDisabled(false);
        }

        const payload = {
            body: {
                start: startTimestamp,
                end: Date.now(),
                label: props.label,
                device: props.device
            }
        };

        setStartTimestamp(null);

        console.log(payload);

        API.post('Training', '/label', payload)
            .then(response => {
                setButtonVariant('success');
                setButtonContent('Saved! ' + response.labelledCount + ' points labelled');
                setTimeout(resetButton, 1000);
                console.log(response);

            })
            .catch(error => {
                setButtonVariant('danger');
                setButtonContent('Error');
                setTimeout(resetButton, 1000);
                console.log(error.response);
            });

    }

    return (
        <TransitionGroup
          transitionName="trainingButton"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}>
            <Button
                disabled={buttonDisabled}
                variant={buttonVariant}
                onMouseDown={() => startPress()}
                onTouchStart={() => startPress()}
                onMouseUp={() => endPress()}
                onTouchEnd={() => endPress()}
                {...props}
                >
                {buttonContent}
                
            </Button>
        </TransitionGroup>
    )
}


const Training = (props) => {
    logger.info('Training');

    const [hasFetchedDevices, setHasFetchedDevices] = useState(false);
    const [userDevices, setUserDevices] = useState([]);
    const [activeDevice, setActiveDevice] = useState(null);

    useEffect(() => {
        if (!hasFetchedDevices) {
            API.get('Training', '/devices', { headers: {} })
                .then(response => {
                    setUserDevices(response.devices);
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
            setHasFetchedDevices(true);
        }
    }, [hasFetchedDevices]);

    function handleDeviceChange(event) {
        console.log(event.target.value);
        setActiveDevice(event.target.value);
    }

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Centering>
                            <h1>Training</h1>
                            <Form.Group>
                                <Form.Control as="select" onChange={handleDeviceChange}>
                                    <option disabled>Select device</option>
                                    {
                                        userDevices.map((element, idx) => {
                                            return (<option value={element.principal + '/' + element.device}>{element.device}</option>)
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Centering>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-xs-center">
                    <Col xs={6} sm={4} md={3} lg={2} style={{ marginBottom: '15px' }} >
                        <TrainingButton
                            style={{ height: '6em', width: '100%' }}
                            label='sitting'
                            device={activeDevice}>
                            Sitting
                        </TrainingButton>
                    </Col>
                    <Col xs={6} sm={4} md={3} lg={2} style={{ marginBottom: '15px' }}>
                        <TrainingButton
                            style={{ height: '6em', width: '100%' }}
                            label='lying'
                            device={activeDevice}>
                            Lying
                        </TrainingButton>
                    </Col>
                    <Col xs={6} sm={4} md={3} lg={2} style={{ marginBottom: '15px' }}>
                        <TrainingButton
                            style={{ height: '6em', width: '100%' }}
                            label='running'
                            device={activeDevice}>
                            Running
                        </TrainingButton>
                    </Col>

                    <Col xs={6} sm={4} md={3} lg={2} style={{ marginBottom: '15px' }}>
                        <TrainingButton
                            style={{ height: '6em', width: '100%' }}
                            label='walking'
                            device={activeDevice}>
                            Walking
                        </TrainingButton>
                    </Col>
                    <Col xs={6} sm={4} md={3} lg={2} style={{ marginBottom: '15px' }}>
                        <TrainingButton
                            style={{ height: '6em', width: '100%' }}
                            label='rolling-over'
                            device={activeDevice}>
                            Rolling-over
                        </TrainingButton>
                    </Col>
                    <Col xs={6} sm={4} md={3} lg={2} style={{ marginBottom: '15px' }}>
                        <TrainingButton
                            style={{ height: '6em', width: '100%' }}
                            label='drinking'
                            device={activeDevice}>
                            Drinking
                        </TrainingButton>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Training;