import React from 'react';
import { Router } from "@reach/router";

/* Import the pages of our application */
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Training from './pages/Training';
import Form from "./pages/SignUp";
import { Login, Logout } from './AuthHelpers';
import { Centering, HomeButton } from './components/UIComponents';
import Calendar  from "./pages/AppointmentScheduler";
// import withDragDropContext from "./pages/AppointmentScheduler";


/* Import the UI components that help our application */
import {
    ProtectedComponent,
    UnprotectedComponent
} from './ComponentTypes';
import { Logger } from 'aws-amplify';

const logger = new Logger('Routing');
/* Defing 'Routes' and export */
const Routes = () => (
  <Router className="full-height">
    <UnprotectedComponent path="/" as={Home} />
    <ProtectedComponent path="/dashboard" as={Dashboard} />
    <ProtectedComponent path="/training" as={Training} />
    <UnprotectedComponent path="/login" as={Login} />
    <UnprotectedComponent path="/logout" as={Logout} />
    <UnprotectedComponent path="/appointments" as={Calendar} />
    <UnprotectedComponent path="/signup" as={Form} />
    <NotFound default />
  </Router>
);

export default Routes;

const NotFound = (props) => {
    logger.debug('Props: ', props);
    return (
        <Centering>
            <h3>404 - Not Found</h3>
            <p>Sorry, <strong>{props.uri}</strong> is not an available path.</p>
            <HomeButton />
        </Centering>
    )
}