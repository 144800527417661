import React from 'react';
import { Hub, Cache } from 'aws-amplify';
import { navigate } from '@reach/router';
import Routes from "./Routing";
import { Navigation } from './components/UIComponents';
import { notify } from './components/NotificationCentre';
import { UserProvider } from './Context';
import { checkUser } from './AuthHelpers';
import { Logger } from 'aws-amplify';
import "./App.scss";

const logger = new Logger('App');

export const PhaseTypes = {
    LOGIN: 'logging_in',
	LOGOUT: 'logging_out',
	CHECKING: 'checking',
    NOMINAL: null
}

class App extends React.Component {

	constructor(props) {
		super(props);

		const urlParams = new URLSearchParams(window.location.search);

		const code = urlParams.get('code');
		const state = urlParams.get('state');

		var _phase = PhaseTypes.NOMINAL;
		if (code && state) {
			_phase = PhaseTypes.LOGIN;
		}

		this.state = {
			// user: null,
			credentials: null,
			setCredentials: _credentials => {
				logger.debug('setCredentials');
				logger.debug('Credentials: ', _credentials);
				this.setState({ credentials: _credentials });
				if (_credentials) {
					this.setState({ phase: null });
				}
			},
			// setUser: userAttributes => {
			// 	logger.debug('User Attributes');
			// 	logger.debug(userAttributes);
			// 	this.setState({ user: userAttributes});

			// },
			phase: _phase,
			setLoggingin: () => {
				logger.debug('setLoggingin');
				this.setState({ phase: PhaseTypes.LOGIN });
			},
			setLoggingout: () => {
				logger.debug('setLoggingout');
				this.setState({ phase: PhaseTypes.LOGOUT });
			},
			setNominal: () => {
				logger.debug('setNominal');
				this.setState({ phase: PhaseTypes.NOMINAL });
			},
			setChecking: () => {
				logger.debug('setChecking');
				this.setState({ phase: PhaseTypes.CHECKING });
			}
		};

		this.redirect = Cache.getItem('auth_redirect');
		Cache.setItem('auth_redirect', null);
	}

	async componentDidMount() {

		await checkUser().then(
			userAttributes => this.state.setCredentials(userAttributes)
			);

		if (this.redirect) {
			navigate(this.redirect);
		}
		

	Hub.listen("auth", async ({ payload: { event, data, message } }) => {
			logger.debug('Event: ', event);
			logger.debug('Data: ', data);
			logger.debug('Message: ', message);
            switch (event) {
              case "signIn":
                logger.debug("Sign In");
                await checkUser().then(userAttributes =>
                  this.state.setCredentials(userAttributes)
                );
                logger.debug("User Attributes: ", this.state.credentials);
                notify(
                  "Welcome back!",
                  "You've signed in as " + this.state.credentials.name
                );
                break;
              case "signOut":
                logger.debug("Sign Out");
                this.state.setCredentials(null);
                notify("Signed out!", "Goodbye!");
                break;
              case "oAuthSignOut":
                logger.debug("oAuthSignOut");
                this.state.setCredentials(null);
                break;
              case "cognitoHostedUI":
                logger.debug("Confnitio Hosted UI");
                this.state.setCredentials(data);
                break;
              case "signUp_failure":
                logger.error("SignUp Failure");
                this.state.setCredentials(null);
                break;
              case "signIn_failure":
                logger.error("Signing Failure");
                this.state.setCredentials(null);
                // this.state.setRetrieving(false);
                break;
              default:
                logger.error("Unknown event: ", event);
                logger.debug(event);
                logger.debug(data);
            }
		});
	}	

	render() {
		return (
			<UserProvider value={this.state}>
				<Navigation />
				<Routes />
			</UserProvider>
		)
	}
}

export default App;