import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import UserContext from './Context';
import { Protect } from './AuthHelpers';
import { Logger } from 'aws-amplify';

const logger = new Logger('ComponentTypes');

export const UnprotectedComponent = (props) => {
    logger.info("UnprotectedComponent");
    let { as: Comp, style } = props;
    return (
        <Container fluid className="main">
            <Comp style={style} />
        </Container>
    )
}

export const ProtectedComponent = (props) => {
    const userContext = useContext(UserContext);
    let { as: Comp, style } = props;
    var content = <Protect redirect={props.path} protected={true} />
    if (userContext.credentials) {
        content = <Comp style={style} />
    }

    return (
        <Container fluid className="main">
            {content}
        </Container>
    );
}
