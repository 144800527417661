import React from "react";
import Tooltip from "@atlaskit/tooltip";
import Select, { components } from "react-select";
import VetAvatars from './Avatar';

const customMultiValueLabel = props => {
    console.log(props);
  return (
    <Tooltip content={"Customise your multi-value label component!"}>
      <components.MultiValueLabel {...props} />
    </Tooltip>
  );
};

export default () => (
  <Select
    closeMenuOnSelect={false}
    components={{ MultiValueLabel: customMultiValueLabel }}
    styles={{
      multiValueLabel: base => ({
        ...base,
        backgroundColor: "blue",
        color: "white"
      })
    }}
    // defaultValue={[colourOptions[4], colourOptions[5]]}
    isMulti
    options={VetAvatars()}
  />
);
