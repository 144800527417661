import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from "aws-amplify";
import { Logger } from "aws-amplify";
import './index.css';

import App from './App';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCheckSquare, faFileUpload, faClock,
    faTachometerAlt, faDownload, faSignOutAlt
 } from '@fortawesome/free-solid-svg-icons';

import * as serviceWorker from './serviceWorker';
import aws_exports from './aws-exports';
window.LOG_LEVEL = 'DEBUG' 
Amplify.Logger.LOG_LEVEL = 'DEBUG';
const logger = new Logger('AnimalAnalytics');
logger.debug("AnimalAnalytics");

library.add(
    faCheckSquare, faFileUpload, faClock,
    faTachometerAlt, faDownload, faSignOutAlt
);

Amplify.configure(aws_exports);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
